import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap"
import axios from "axios"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
const baseURL = process.env.REACT_APP_API_URL

const AdminMenu = props => {
  document.title = "Menu | LS-DA - Softlogy"

  const navigate = useNavigate()

  const [menuOptions, setMenuOptions] = useState([])
  const [newMenuOption, setNewMenuOption] = useState({
    title: "",
    path: "",
    icon: "",
    children: [],
  })
  const [currentChild, setCurrentChild] = useState({
    title: "",
    path: "",
    children: [],
  })
  const [currentSubChild, setCurrentSubChild] = useState({
    title: "",
    path: "",
    children: [],
  })
  const [editingIndex, setEditingIndex] = useState(null)
  const [isEditingChild, setIsEditingChild] = useState(false)

  useEffect(() => {
    fetchMenuOptions()
  }, [])

  const fetchMenuOptions = async () => {
    try {
      const response = await axios.get(`${baseURL}menu/options`)
      setMenuOptions(response.data)
    } catch (error) {
      console.error("Error fetching menu options:", error)
    }
  }

  const handleInputChange = (e, level = "menu") => {
    const { name, value } = e.target

    if (level === "menu") {
      setNewMenuOption({ ...newMenuOption, [name]: value })
    } else if (level === "child") {
      setCurrentChild({ ...currentChild, [name]: value })
    } else if (level === "subchild") {
      setCurrentSubChild({ ...currentSubChild, [name]: value })
    }
  }

  const addChildOption = () => {
    setNewMenuOption({
      ...newMenuOption,
      children: [...newMenuOption.children, { ...currentChild }],
    })
    setCurrentChild({ title: "", path: "", children: [] })
  }

  const addSubChildToChild = () => {
    const updatedChildren = [...currentChild.children, { ...currentSubChild }]
    setCurrentChild({ ...currentChild, children: updatedChildren })
    setCurrentSubChild({ title: "", path: "", children: [] })
  }

  const createMenuOption = async () => {
    try {
      await axios.post(`${baseURL}menu/options`, newMenuOption)
      fetchMenuOptions()
      setNewMenuOption({ title: "", path: "", icon: "", children: [] })
    } catch (error) {
      console.error("Error creating menu option:", error)
    }
  }

  const editMenuOption = index => {
    setEditingIndex(index)
    setNewMenuOption(menuOptions[index])
    setIsEditingChild(false)
  }

  const editChildOption = (menuIndex, childIndex) => {
    const selectedChild = menuOptions[menuIndex].children[childIndex]
    setCurrentChild(selectedChild)
    setEditingIndex({ menuIndex, childIndex })
    setIsEditingChild(true)
  }

  const editSubChildOption = index => {
    const selectedSubChild = currentChild.children[index]
    setCurrentSubChild(selectedSubChild)
    setEditingIndex({ subChildIndex: index })
  }

  const saveMenuOption = async () => {
    if (editingIndex !== null) {
      if (isEditingChild) {
        // Editar Submenú
        const updatedMenuOptions = [...menuOptions]
        updatedMenuOptions[editingIndex.menuIndex].children[
          editingIndex.childIndex
        ] = currentChild

        try {
          await axios.put(
            `${baseURL}menu/options/${menuOptions[editingIndex.menuIndex]._id}`,
            updatedMenuOptions[editingIndex.menuIndex],
          )
          fetchMenuOptions()
        } catch (error) {
          console.error("Error updating submenu:", error)
        }

        setMenuOptions(updatedMenuOptions)
        setCurrentChild({ title: "", path: "", children: [] })
        setIsEditingChild(false)
        setEditingIndex(null)
      } else {
        // Editar Menú Principal
        const updatedMenu = await axios.put(
          `${baseURL}menu/options/${menuOptions[editingIndex]._id}`,
          newMenuOption,
        )
        console.log("Updated menu option:", updatedMenu.data)
        fetchMenuOptions()
        setEditingIndex(null)
        setNewMenuOption({ title: "", path: "", icon: "", children: [] })
      }
    } else {
      // Crear un nuevo menú o submenú
      try {
        await axios.post(`${baseURL}menu/options`, newMenuOption)
        fetchMenuOptions()
        setNewMenuOption({ title: "", path: "", icon: "", children: [] })
      } catch (error) {
        console.error("Error creating menu option:", error)
      }
    }
  }

  const removeChildOption = async (menuIndex, childIndex = null) => {
    let updatedMenuOptions = [...menuOptions]

    if (childIndex === null) {
      // Eliminar un menú principal
      const menuId = updatedMenuOptions[menuIndex]._id
      updatedMenuOptions.splice(menuIndex, 1)

      try {
        await axios.delete(`${baseURL}menu/options/${menuId}`)
        setMenuOptions(updatedMenuOptions)
      } catch (error) {
        console.error("Error deleting menu:", error)
      }
    } else {
      // Eliminar un submenú
      const childId = updatedMenuOptions[menuIndex].children[childIndex]._id
      updatedMenuOptions[menuIndex].children.splice(childIndex, 1)

      try {
        await axios.post(`${baseURL}menu-options/remove-child`, {
          menuId: updatedMenuOptions[menuIndex]._id,
          childId: childId,
        })
        setMenuOptions(updatedMenuOptions)
      } catch (error) {
        console.error("Error deleting submenu:", error)
      }
    }
  }

  const renderSubLevels = (children, level = 0) => {
    return children.map((child, index) => (
      <div
        key={index}
        style={{ marginLeft: `${level * 20}px`, marginBottom: "10px" }}
      >
        <span>
          {child.title} - {child.path}
        </span>
        <ButtonGroup className="ms-2">
          <Button
            color="danger"
            onClick={() => removeChildOption(editingIndex.menuIndex, index)}
            size="sm"
          >
            Eliminar
          </Button>
          <Button
            color="warning"
            onClick={() => editSubChildOption(index)}
            size="sm"
          >
            Editar
          </Button>
        </ButtonGroup>
        {child.children && child.children.length > 0 && (
          <div>{renderSubLevels(child.children, level + 1)}</div>
        )}
      </div>
    ))
  }

  const renderMenuOptions = (options, level = 0) => {
    return options.map((option, menuIndex) => (
      <div
        key={menuIndex}
        style={{ marginLeft: `${level * 20}px`, marginBottom: "15px" }}
      >
        <span>
          {option.title} - {option.path}
        </span>
        <ButtonGroup className="ms-2">
          <Button
            color="danger"
            onClick={() => removeChildOption(menuIndex)}
            size="sm"
          >
            Eliminar
          </Button>
          <Button
            color="warning"
            onClick={() => editMenuOption(menuIndex)}
            size="sm"
          >
            Editar
          </Button>
        </ButtonGroup>
        {option.children && option.children.length > 0 && (
          <div>
            {option.children.map((child, childIndex) => (
              <div
                key={childIndex}
                style={{
                  marginLeft: `${(level + 1) * 20}px`,
                  marginBottom: "10px",
                }}
              >
                <span>
                  {child.title} - {child.path}
                </span>
                <ButtonGroup className="ms-2">
                  <Button
                    color="danger"
                    onClick={() => removeChildOption(menuIndex, childIndex)}
                    size="sm"
                  >
                    Eliminar
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => editChildOption(menuIndex, childIndex)}
                    size="sm"
                  >
                    Editar
                  </Button>
                </ButtonGroup>
                {child.children && child.children.length > 0 && (
                  <div>{renderSubLevels(child.children, level + 2)}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h4 className="card-title">Administrar Opciones de Menú</h4>
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="menuTitle">Título del Menú</Label>
                      <Input
                        type="text"
                        id="menuTitle"
                        name="title"
                        value={newMenuOption.title}
                        onChange={e => handleInputChange(e, "menu")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="menuPath">Ruta del Menú</Label>
                      <Input
                        type="text"
                        id="menuPath"
                        name="path"
                        value={newMenuOption.path}
                        onChange={e => handleInputChange(e, "menu")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="menuIcon">Ícono del Menú</Label>
                      <Input
                        type="text"
                        id="menuIcon"
                        name="icon"
                        value={newMenuOption.icon}
                        onChange={e => handleInputChange(e, "menu")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <h5>Submenús</h5>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="childTitle">Título del Submenú</Label>
                      <Input
                        type="text"
                        id="childTitle"
                        name="title"
                        value={currentChild.title}
                        onChange={e => handleInputChange(e, "child")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="childPath">Ruta del Submenú</Label>
                      <Input
                        type="text"
                        id="childPath"
                        name="path"
                        value={currentChild.path}
                        onChange={e => handleInputChange(e, "child")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <Button
                      color="primary"
                      onClick={addChildOption}
                      className="mt-3"
                    >
                      Agregar Submenú
                    </Button>
                  </Col>
                </Row>
                <hr />
                <h5 className="mt-4">Subniveles del Submenú</h5>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="subChildTitle">Título del Subnivel</Label>
                      <Input
                        type="text"
                        id="subChildTitle"
                        name="title"
                        value={currentSubChild.title}
                        onChange={e => handleInputChange(e, "subchild")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="subChildPath">Ruta del Subnivel</Label>
                      <Input
                        type="text"
                        id="subChildPath"
                        name="path"
                        value={currentSubChild.path}
                        onChange={e => handleInputChange(e, "subchild")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Button
                      color="primary"
                      onClick={addSubChildToChild}
                      className="mt-3"
                    >
                      Agregar Subnivel
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Button
                  color="success"
                  onClick={saveMenuOption}
                  className="mt-3"
                >
                  {editingIndex !== null
                    ? "Guardar Cambios"
                    : "Crear Opción de Menú"}
                </Button>
              </Form>
              <hr />
              <div className="mt-3">
                <h5>Opciones de Menú</h5>
                {renderMenuOptions(menuOptions)}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(AdminMenu)
