import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

const baseURL = process.env.REACT_APP_API_URL

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(axios.post, `${baseURL}auth/login`, {
      email: user.email,
      password: user.password,
    })

    const { access, expiresIn = 3600 } = response.data // Proporcionar un valor predeterminado de 3600 segundos (1 hora)

    const expirationTime = new Date().getTime() + expiresIn * 1000

    const userResponse = yield call(axios.get, `${baseURL}usuarios/me`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })

    const userData = { ...userResponse.data, access, expirationTime } // Add access token to user data
    localStorage.setItem("authUser", JSON.stringify(userData))
    yield put(loginSuccess(userData))
    history("/portal")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history("/login")
    yield put(logoutUserSuccess())
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    // Manejar login social
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
}

export default authSaga
