import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import Miniwidget from "./Miniwidget"
import LatestTransactions from "./latest-transactions"
import { Row, Col } from "reactstrap"

import axios from "axios"
import ConsultaEventosDia from "pages/Comprobantes/ConsultaEventosDia"
const baseURL = process.env.REACT_APP_API_URL

const Dashboard = ({ setBreadcrumbItems, user }) => {
  const [documentos, setDocumentos] = useState([])
  const [reports, setReports] = useState([])

  document.title = "LS-DA / Softlogy"

  const userGreeting = `Bienvenido, ${user ? `${user.nombre} ${user.apellido}` : "Usuario"}`

  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchResumenFacturas = async () => {
    console.log("fetchResumenFacturas")
    //console.log(user)
    console.log(user.empresas)
    console.log(
      "EEEEE:",
      user.empresas.map(empresa => empresa.idempresa.identificacion),
    )
    console.log(`${baseURL}recepcion/resumen-facturas`)

    try {
      const nits = user.empresas.map(
        empresa => empresa.idempresa.identificacion,
      )
      const response = await axios.post(
        `${baseURL}recepcion/resumen-facturas`,
        { nits },
      )

      const data = response.data
      const reportsData = []

      Object.keys(data).forEach(nit => {
        const empresa = user.empresas.find(
          e => e.idempresa.NumeroIdentificacion === nit,
        )
        const nombreEmpresa = empresa ? empresa.idempresa.Nombre : nit

        Object.keys(data[nit]).forEach(year => {
          const tiposDocumento = data[nit][year].tiposDocumento

          Object.keys(tiposDocumento).forEach(tipoDocumento => {
            if (tipoDocumento !== "Desconocido") {
              const { cantidad, subtotal, IVA, INC, total } =
                tiposDocumento[tipoDocumento]

              reportsData.push({
                title: `${nombreEmpresa} - ${year} - ${getDocumentoNombre(tipoDocumento)}`,
                cantidad,
                year,
                nombreEmpresa,
                tipodoc: getDocumentoNombre(tipoDocumento),
                subtotal: subtotal.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                }),
                IVA: IVA.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                }),
                INC: INC.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                }),
                total: total.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                }),
                badgecolor: "info",
              })
            }
          })
        })
      })

      setReports(reportsData)
    } catch (error) {
      console.error("Error fetching resumen facturas:", error)
    }
  }

  useEffect(() => {
    if (user) {
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Dashboard", link: "#" },
      ]
      setBreadcrumbItems(userGreeting, breadcrumbItems)
      fetchTipoDocumento()
    }
  }, [setBreadcrumbItems, user, userGreeting])

  useEffect(() => {
    if (documentos.length > 0) {
      fetchResumenFacturas()
    }
  }, [documentos])

  const getDocumentoNombre = code => {
    const doc = documentos.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  return (
    <React.Fragment>
      {/*  <Miniwidget reports={reports} />*/}

      <Row>
        <Col xl="12">
          {/* latest transactions */}
          <LatestTransactions transactions={reports} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  user: state.Login.user,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Dashboard)
