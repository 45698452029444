import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  UncontrolledAlert,
} from "reactstrap"
import Switch from "react-switch"
import { MDBDataTable } from "mdbreact"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"
import ReactPaginate from "react-paginate"

const CrearEmpresa = props => {
  document.title = "Crear Empresa | LS-DA - Softlogy"

  const navigate = useNavigate()

  const [empresa, setEmpresa] = useState({
    nombre: "",
    tipodocumento: "",
    identificacion: "",
    digitoverificador: "",
    activo: true,
    datosempresa: {},
    datosdian: {},
    configuracion: {},
    datoslsda: {},
    sucursales: [],
  })

  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" })
  const [newSucursal, setNewSucursal] = useState({
    brandid: "",
    storeid: "",
    Nombretienda: "",
  })
  const [documentTypes, setDocumentTypes] = useState([])
  const [paises, setPaises] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [ciudades, setCiudades] = useState([])
  const [regimenes, setRegimenes] = useState([])
  const [tiposPersona, setTiposPersona] = useState([])
  const [obligacionesFiscales, setObligacionesFiscales] = useState([])
  const [obligacionesImpuestos, setObligacionesImpuestos] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  const [aliansas, setAliansas] = useState([])

  const fetchAlianzas = useCallback(async () => {
    try {
      //console.log("auto:", props.authData.access)
      const response = await axios.get(
        `http://localhost:4000/hub-latam/rc1/empresas/empresa/alianzas`,
        {
          headers: {
            Authorization: `${props.authData.access}`,
          },
        },
      )

      //console.log("Alianzas:", response.data)
      setAliansas(response.data)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching alianzas data:", error)
      setAlert({
        show: true,
        message: "Error fetching alianzas data",
        variant: "danger",
      })
      setLoading(false)
    }
  }, [props.authData])

  const fetchDocumentTypes = async () => {
    try {
      const response = await axios.get("/listas/TipoIdFiscal-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentTypes(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchPaises = async () => {
    try {
      const presponse = await axios.get("/listas/Paises-2.1.gc")
      const pxmlText = presponse.data
      const pparser = new DOMParser()
      const pxmlDoc = pparser.parseFromString(pxmlText, "application/xml")
      const prows = pxmlDoc.getElementsByTagName("Row")
      const ptypes = []

      for (let row of prows) {
        const pcodeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const pnameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (pcodeValue && pnameValue) {
          ptypes.push({
            code: pcodeValue.textContent,
            name: pnameValue.textContent,
          })
        }
      }

      setPaises(ptypes)
    } catch (error) {
      console.error("Error fetching paises types:", error)
    }
  }

  const fetchDepartamentos = async () => {
    try {
      const response = await axios.get("/listas/Departamentos-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const departamentos = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          departamentos.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDepartamentos(departamentos)
    } catch (error) {
      console.error("Error fetching departamentos:", error)
    }
  }

  const fetchCiudades = async departamento => {
    try {
      const response = await axios.get("/listas/Municipio-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const ciudades = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (
          codeValue &&
          nameValue &&
          codeValue.textContent.startsWith(departamento)
        ) {
          ciudades.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setCiudades(ciudades)
    } catch (error) {
      console.error("Error fetching ciudades:", error)
    }
  }

  const fetchRegimenes = async () => {
    try {
      const response = await axios.get("/listas/TipoRegimen-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const regimenes = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          regimenes.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setRegimenes(regimenes)
    } catch (error) {
      console.error("Error fetching regimenes:", error)
    }
  }

  const fetchTiposPersona = async () => {
    try {
      const response = await axios.get("/listas/TipoOrganizacion-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const tiposPersona = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          tiposPersona.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setTiposPersona(tiposPersona)
    } catch (error) {
      console.error("Error fetching tipos de persona:", error)
    }
  }

  const fetchObligacionesFiscales = async () => {
    try {
      const response = await axios.get("/listas/TipoResponsabilidad-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const obligacionesFiscales = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          obligacionesFiscales.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setObligacionesFiscales(obligacionesFiscales)
    } catch (error) {
      console.error("Error fetching obligaciones fiscales:", error)
    }
  }

  const fetchObligacionesImpuestos = async () => {
    try {
      const response = await axios.get("/listas/ObligacionesImpuestos-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const obligacionesImpuestos = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          obligacionesImpuestos.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setObligacionesImpuestos(obligacionesImpuestos)
    } catch (error) {
      console.error("Error fetching obligaciones de impuestos:", error)
    }
  }

  useEffect(() => {
    fetchDocumentTypes()
    fetchPaises()
    fetchDepartamentos()
    fetchRegimenes()
    fetchTiposPersona()
    fetchObligacionesFiscales()
    fetchObligacionesImpuestos()
    fetchAlianzas()
  }, [props.authData])

  useEffect(() => {
    if (empresa.datosempresa && empresa.datosempresa.departamento) {
      fetchCiudades(empresa.datosempresa.departamento)
    }
  }, [empresa.datosempresa])

  const handleInputChange = e => {
    const { name, value, dataset } = e.target
    const section = dataset.section
    setEmpresa(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }))
  }

  const handleAddSucursal = () => {
    setEmpresa(prev => ({
      ...prev,
      sucursales: [...prev.sucursales, newSucursal],
    }))
    setNewSucursal({ brandid: "", storeid: "", Nombretienda: "" })
  }

  const handleRemoveSucursal = index => {
    setEmpresa(prev => ({
      ...prev,
      sucursales: prev.sucursales.filter((_, i) => i !== index),
    }))
  }

  const handleNewSucursalChange = e => {
    const { name, value } = e.target
    setNewSucursal(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      //console.log("Empresa:", empresa)
      await axios.post(
        "http://localhost:4000/hub-latam/rc1/empresas/crear",
        empresa,
        {
          headers: {
            Authorization: `Bearer ${props.authData.access}`,
          },
        },
      )
      setAlert({
        show: true,
        message: "Empresa creada exitosamente",
        variant: "success",
      })
    } catch (error) {
      console.error("Error creating empresa data:", error)
      setAlert({
        show: true,
        message: "Error creating empresa data",
        variant: "danger",
      })
    }
  }

  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(0)

  const offset = currentPage * itemsPerPage
  const currentPageData = empresa.sucursales.slice(
    offset,
    offset + itemsPerPage,
  )

  const dataTableData = {
    columns: [
      { label: "Brand ID", field: "brandid", sort: "asc", width: 150 },
      { label: "Store ID", field: "storeid", sort: "asc", width: 150 },
      {
        label: "Nombre Tienda",
        field: "Nombretienda",
        sort: "asc",
        width: 270,
      },
      { label: "Acciones", field: "acciones", sort: "asc", width: 150 },
    ],
    rows: [
      ...currentPageData.map((sucursal, index) => ({
        brandid: sucursal.brandid,
        storeid: sucursal.storeid,
        Nombretienda: sucursal.Nombretienda,
        acciones: (
          <Button color="danger" onClick={() => handleRemoveSucursal(index)}>
            Eliminar
          </Button>
        ),
      })),
      {
        brandid: (
          <Input
            type="text"
            name="brandid"
            placeholder="Brand ID"
            value={newSucursal.brandid}
            onChange={handleNewSucursalChange}
          />
        ),
        storeid: (
          <Input
            type="text"
            name="storeid"
            placeholder="Store ID"
            value={newSucursal.storeid}
            onChange={handleNewSucursalChange}
          />
        ),
        Nombretienda: (
          <Input
            type="text"
            name="Nombretienda"
            placeholder="Nombre Tienda"
            value={newSucursal.Nombretienda}
            onChange={handleNewSucursalChange}
          />
        ),
        acciones: (
          <Button color="primary" onClick={handleAddSucursal}>
            Agregar
          </Button>
        ),
      },
    ],
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  ////console.log(currentPageData)

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Crear Empresa</CardTitle>

                {alert.show && (
                  <UncontrolledAlert
                    variant={alert.variant}
                    onClose={() => setAlert({ show: false })}
                    dismissible
                  >
                    {alert.message}
                  </UncontrolledAlert>
                )}

                <div>
                  <Button color="primary" onClick={handleSubmit}>
                    Guardar Empresa
                  </Button>
                  <Button
                    color="danger"
                    className="ms-2"
                    onClick={() => navigate("/portal/consultar-empresas")}
                  >
                    Cerrar
                  </Button>
                </div>
              </div>
              {loading ? (
                <p>Cargando...</p>
              ) : (
                <>
                  <Form>
                    <hr />

                    <CardTitle className="h4">Información General</CardTitle>

                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="nombre">Nombre</Label>
                          <Input
                            type="text"
                            id="nombre"
                            value={empresa.nombre || ""}
                            onChange={e =>
                              setEmpresa({ ...empresa, nombre: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="tipodocumento">Tipo de Documento</Label>
                          <Input
                            type="select"
                            id="tipodocumento"
                            value={empresa.tipodocumento || ""}
                            onChange={e =>
                              setEmpresa({
                                ...empresa,
                                tipodocumento: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              Seleccione un tipo de documento
                            </option>
                            {documentTypes.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <FormGroup>
                          <Label for="identificacion">Identificación</Label>
                          <Input
                            type="text"
                            id="identificacion"
                            value={empresa.identificacion || ""}
                            onChange={e =>
                              setEmpresa({
                                ...empresa,
                                identificacion: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <Label for="digitoverificador">DV</Label>
                          <Input
                            type="text"
                            id="digitoverificador"
                            value={empresa.digitoverificador || ""}
                            onChange={e =>
                              setEmpresa({
                                ...empresa,
                                digitoverificador: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label>Activo</Label>
                          <Row>
                            <Switch
                              onColor="#02a499"
                              onChange={() =>
                                setEmpresa({
                                  ...empresa,
                                  activo: !empresa.activo,
                                })
                              }
                              checked={empresa.activo || false}
                            />
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Row>
                        <CardTitle className="h4">
                          Datos de la Empresa
                        </CardTitle>
                      </Row>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="pais">País</Label>
                          <Input
                            type="select"
                            id="pais"
                            value={empresa.datosempresa?.pais || "CO"}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosempresa: {
                                  ...prev.datosempresa,
                                  pais: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">Seleccione un País</option>
                            {paises.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="departamento">Departamento</Label>
                          <Input
                            type="select"
                            id="departamento"
                            value={empresa.datosempresa?.departamento || "CO"}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosempresa: {
                                  ...prev.datosempresa,
                                  departamento: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">Seleccione un Departamento</option>
                            {departamentos.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="ciudad">Ciudad</Label>
                          <Input
                            type="select"
                            id="ciudad"
                            value={empresa.datosempresa?.ciudad || "CO"}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosempresa: {
                                  ...prev.datosempresa,
                                  ciudad: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">Seleccione una Ciudad</option>
                            {ciudades.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="direccion">Dirección</Label>
                          <Input
                            type="text"
                            id="direccion"
                            name="direccion"
                            data-section="datosempresa"
                            value={empresa.datosempresa.direccion || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="telefono">Teléfono</Label>
                          <Input
                            type="text"
                            id="telefono"
                            name="telefono"
                            data-section="datosempresa"
                            value={empresa.datosempresa.telefono || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="correo">Correo</Label>
                          <Input
                            type="email"
                            id="correo"
                            name="correo"
                            data-section="datosempresa"
                            value={empresa.datosempresa.correo || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup>
                          <Label for="mailcontacto">Mail Contacto</Label>
                          <Input
                            type="email"
                            id="mailcontacto"
                            name="mailcontacto"
                            data-section="datosempresa"
                            value={empresa.datosempresa.mailcontacto || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="nombrecomercial">Nombre Comercial</Label>
                          <Input
                            type="text"
                            id="nombrecomercial"
                            name="nombrecomercial"
                            data-section="datosempresa"
                            value={empresa.datosempresa.nombrecomercial || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="leyenda">Leyenda</Label>
                          <Input
                            type="text"
                            id="leyenda"
                            name="leyenda"
                            data-section="datosempresa"
                            value={empresa.datosempresa.leyenda || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Row>
                        <CardTitle className="h4">Datos DIAN</CardTitle>
                      </Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="regimen">Régimen</Label>
                          <Input
                            type="select"
                            id="regimen"
                            value={empresa.datosdian?.regimen || "CO"}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosdian: {
                                  ...prev.datosdian,
                                  regimen: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">Seleccione un Régimen</option>
                            {regimenes.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="obligacionesfiscales">
                            Obligaciones Fiscales
                          </Label>
                          <Input
                            type="select"
                            id="obligacionesfiscales"
                            value={
                              empresa.datosdian?.obligacionesfiscales || "CO"
                            }
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosdian: {
                                  ...prev.datosdian,
                                  obligacionesfiscales: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">
                              Seleccione Obligaciones Fiscales
                            </option>
                            {obligacionesFiscales.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="obligacionesimpuestos">
                            Obligaciones de Impuestos
                          </Label>
                          <Input
                            type="select"
                            id="obligacionesimpuestos"
                            value={
                              empresa.datosdian?.obligacionesimpuestos || "CO"
                            }
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosdian: {
                                  ...prev.datosdian,
                                  obligacionesimpuestos: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">
                              Seleccione Obligaciones de Impuestos
                            </option>
                            {obligacionesImpuestos.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="tipopersona">Tipo de Persona</Label>
                          <Input
                            type="select"
                            id="tipopersona"
                            value={empresa.datosdian?.tipopersona || "CO"}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datosdian: {
                                  ...prev.datosdian,
                                  tipopersona: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">
                              Seleccione un Tipo de Persona
                            </option>
                            {tiposPersona.map(type => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Row>
                        <CardTitle className="h4">ID Software</CardTitle>
                      </Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="idsoftware">ID Software</Label>
                          <Input
                            type="text"
                            id="idsoftware"
                            name="idsoftware"
                            data-section="configuracion"
                            value={empresa.configuracion.idsoftware || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <Label for="pin">PIN</Label>
                          <Input
                            type="text"
                            id="pin"
                            name="pin"
                            data-section="configuracion"
                            value={empresa.configuracion.pin || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup>
                          <Label for="logo">Logo</Label>
                          <Input
                            type="text"
                            id="logo"
                            name="logo"
                            data-section="configuracion"
                            value={empresa.configuracion.logo || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label>Genera PDF</Label>
                          <div className="d-flex align-items-center">
                            <Switch
                              onColor="#02a499"
                              onChange={() =>
                                setEmpresa(prev => ({
                                  ...prev,
                                  configuracion: {
                                    ...prev.configuracion,
                                    generapdf: !prev.configuracion.generapdf,
                                  },
                                }))
                              }
                              checked={empresa.configuracion.generapdf || false}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="certificado">Certificado</Label>
                          <Input
                            type="text"
                            id="certificado"
                            name="certificado"
                            data-section="configuracion"
                            value={empresa.configuracion.certificado || ""}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="password">Password</Label>
                          <div className="d-flex align-items-center">
                            <Input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              data-section="configuracion"
                              value={empresa.configuracion.password || ""}
                              onChange={handleInputChange}
                            />
                            <Button
                              outline
                              color="secondary"
                              className="ms-2"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "Ocultar" : "Mostrar"}
                            </Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Row>
                        <CardTitle className="h4">Datos LS-DA</CardTitle>
                      </Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="tipoempresa">Tipo de empresa</Label>
                          <Input
                            type="select"
                            id="tipo"
                            value={empresa.datoslsda?.tipo || ""}
                            onChange={e =>
                              setEmpresa(prev => ({
                                ...prev,
                                datoslsda: {
                                  ...prev.datoslsda,
                                  tipo: e.target.value,
                                },
                              }))
                            }
                          >
                            <option value="">
                              Seleccione un Tipo de Empresa
                            </option>
                            <option key={"admin"} value={"admin"}>
                              Admin
                            </option>
                            <option
                              key={"cliente directo"}
                              value={"cliente directo"}
                            >
                              Cliente Directo
                            </option>
                            <option key={"alianza"} value={"alianza"}>
                              Alianza
                            </option>
                            <option
                              key={"clienteDeUnaAlianza"}
                              value={"clienteDeUnaAlianza"}
                            >
                              Cliente de Una Alianza
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      {empresa.datoslsda?.tipo === "clienteDeUnaAlianza" && (
                        <Col md={2}>
                          <FormGroup>
                            <Label for="alianzaId">Alianza</Label>
                            <Input
                              type="select"
                              id="alianzaId"
                              value={empresa.datoslsda?.alianzaId || ""}
                              onChange={e =>
                                setEmpresa(prev => ({
                                  ...prev,
                                  datoslsda: {
                                    ...prev.datoslsda,
                                    alianzaId: e.target.value,
                                  },
                                }))
                              }
                            >
                              <option value="">Seleccione una alianza</option>
                              {aliansas.map(type => (
                                <option key={type.id} value={type.id}>
                                  {type.nombre}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <hr />
                    <Row>
                      <Row>
                        <CardTitle className="h4">Sucursales</CardTitle>
                      </Row>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <MDBDataTable
                          responsive
                          bordered
                          data={dataTableData}
                          entriesLabel="Mostrar _MENU_ registros"
                          searchLabel="Buscar:"
                          infoLabel={[
                            "Mostrando registros del",
                            "al",
                            "de un total de",
                            "registros",
                          ]}
                          paging={false}
                          displayEntries
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <ReactPaginate
                          previousLabel={"← Anterior"}
                          nextLabel={"Siguiente →"}
                          pageCount={Math.ceil(
                            empresa.sucursales.length / itemsPerPage,
                          )}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(CrearEmpresa)
