import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Progress,
  Table,
} from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"

const baseURL = process.env.REACT_APP_API_URL

const ConsultaEventosDian = () => {
  const navigate = useNavigate()
  const [empresas, setEmpresas] = useState([])
  const [nitemisor, setNitemisor] = useState("")
  const [progresos, setProgresos] = useState([]) // Cambiado a lista
  const [loading, setLoading] = useState(false)
  const [refreshing, setRefreshing] = useState(false) // Estado para refrescar
  const [showAll, setShowAll] = useState(false) // Estado para alternar vista
  const [timeLeft, setTimeLeft] = useState(60) // Tiempo restante para la próxima actualización

  // Obtener las empresas disponibles
  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.empresas) {
      console.error("No se encontraron empresas asociadas al usuario.")
      return
    }

    try {
      setEmpresas(currentUser.empresas)
    } catch (error) {
      console.error("Error al obtener las empresas:", error)
    }
  }

  // Consultar progreso desde la API
  const consultarProgreso = async (nit, tipo) => {
    setRefreshing(true)
    try {
      const response = await axios.get(
        `${baseURL}archivos/progreso/${nit}/${tipo}`,
      )
      const tareas = response.data

      // Guardar todos los registros
      setProgresos(
        tareas.sort(
          (a, b) => new Date(b.actualizadoEn) - new Date(a.actualizadoEn),
        ),
      )
    } catch (error) {
      console.error("Error al consultar el progreso:", error)
      setProgresos([])
    } finally {
      setRefreshing(false)
    }
  }

  // Calcular tiempo estimado para finalizar
  // Calcular tiempo estimado para finalizar

  const calcularTiempoEstimado = (progresoActual, progresoAnterior) => {
    const procesadosActual = progresoActual.procesados
    const procesadosInicial = progresoAnterior ? progresoAnterior.procesados : 0

    const pendientes = progresoActual.pendientes

    const tiempoActualizado = new Date(progresoActual.actualizadoEn).getTime()
    const tiempoInicial = new Date(progresoActual.creadoEn).getTime()

    // Tiempo total transcurrido desde el inicio (en segundos)
    const tiempoTranscurridoSegundos =
      (tiempoActualizado - tiempoInicial) / 1000

    // Validar que tengamos datos suficientes
    if (
      procesadosActual > procesadosInicial &&
      tiempoTranscurridoSegundos > 0
    ) {
      const documentosProcesados = procesadosActual - procesadosInicial
      const tasaPromedio = documentosProcesados / tiempoTranscurridoSegundos // Documentos por segundo

      if (tasaPromedio > 0) {
        const tiempoEstimadoSegundos = pendientes / tasaPromedio

        const horas = Math.floor(tiempoEstimadoSegundos / 3600)
        const minutos = Math.floor((tiempoEstimadoSegundos % 3600) / 60)
        const segundos = Math.floor(tiempoEstimadoSegundos % 60)

        return `${horas}h ${minutos}m ${segundos}s`
      }
    }

    return "No disponible"
  }

  // Iniciar la actualización de eventos
  const handleActualizarEventos = async () => {
    if (!nitemisor) {
      console.error("Debe seleccionar una empresa antes de actualizar.")
      return
    }

    try {
      setLoading(true)
      await axios.get(`${baseURL}archivos/obtieneEventosempresa/${nitemisor}`)
      consultarProgreso(nitemisor, "Actualizacion de eventos") // Consultar progreso después de iniciar
    } catch (error) {
      console.error("Error al actualizar eventos:", error)
    } finally {
      setLoading(false)
    }
  }

  // Manejar el cambio de empresa seleccionada
  const handleNitemisorChange = selectedOption => {
    const selectedNit = selectedOption ? selectedOption.value : ""
    setNitemisor(selectedNit)
    setProgresos([]) // Reiniciar progreso
    setTimeLeft(60) // Reiniciar temporizador
    if (selectedNit) {
      consultarProgreso(selectedNit, "Actualizacion de eventos")
    }
  }

  // Temporizador para actualizar automáticamente el progreso
  useEffect(() => {
    if (!showAll && progresos.length > 0) {
      const interval = setInterval(() => {
        setTimeLeft(prev => {
          if (prev === 1) {
            consultarProgreso(nitemisor, "Actualizacion de eventos")
            return 60
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(interval) // Limpiar intervalo al desmontar
    }
  }, [showAll, nitemisor, progresos])

  useEffect(() => {
    fetchEmpresas()
  }, [])

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa.identificacion,
    label: empresa.idempresa.nombre,
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Actualización de Eventos</CardTitle>
                <Button color="danger" onClick={() => navigate("/portal")}>
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Seleccione una empresa para actualizar los eventos y consultar
                el progreso.
              </p>
              <hr />
              <Row>
                <Col md={6}>
                  <Select
                    id="nitemisor"
                    value={empresaOptions.find(
                      option => option.value === nitemisor,
                    )}
                    onChange={handleNitemisorChange}
                    options={empresaOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Seleccione una empresa"
                    isClearable
                  />
                </Col>
                <Col md={3}>
                  {nitemisor && (
                    <Button
                      color="primary"
                      onClick={handleActualizarEventos}
                      disabled={loading}
                    >
                      {loading ? "Procesando..." : "Actualizar Eventos"}
                    </Button>
                  )}
                </Col>
                <Col md={3}>
                  {nitemisor && (
                    <Button
                      color="info"
                      onClick={() =>
                        consultarProgreso(nitemisor, "Actualizacion de eventos")
                      }
                      disabled={refreshing}
                    >
                      {refreshing ? "Refrescando..." : "Refrescar Progreso"}
                    </Button>
                  )}
                </Col>
              </Row>
              <hr />
              {progresos.length > 0 && (
                <div>
                  <Button
                    color="secondary"
                    className="mb-3"
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll
                      ? "Ver Último Registro"
                      : "Ver Todos los Registros"}
                  </Button>
                  {showAll ? (
                    // Mostrar todos los registros en una tabla
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Estado</th>
                          <th>Total Documentos</th>
                          <th>Procesados</th>
                          <th>Pendientes</th>
                          <th>Creado En</th>
                          <th>Actualizado En</th>
                        </tr>
                      </thead>
                      <tbody>
                        {progresos.map((progreso, index) => (
                          <tr key={index}>
                            <td>{progreso.estado}</td>
                            <td>{progreso.totalDocumentos}</td>
                            <td>{progreso.procesados}</td>
                            <td>{progreso.pendientes}</td>
                            <td>
                              {new Date(progreso.creadoEn).toLocaleString()}
                            </td>
                            <td>
                              {new Date(
                                progreso.actualizadoEn,
                              ).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    // Mostrar solo el registro más reciente
                    <div>
                      <p>
                        <strong>Estado:</strong> {progresos[0].estado}
                      </p>
                      <p>
                        <strong>Total Documentos:</strong>{" "}
                        {progresos[0].totalDocumentos}
                      </p>
                      <p>
                        <strong>Procesados:</strong> {progresos[0].procesados}
                      </p>
                      <p>
                        <strong>Pendientes:</strong> {progresos[0].pendientes}
                      </p>
                      <p>
                        <strong>Creado En:</strong>{" "}
                        {new Date(progresos[0].creadoEn).toLocaleString()}
                      </p>
                      <p>
                        <strong>Actualizado En:</strong>{" "}
                        {new Date(progresos[0].actualizadoEn).toLocaleString()}
                      </p>
                      <Progress
                        value={
                          progresos[0].totalDocumentos > 0
                            ? (progresos[0].procesados /
                                progresos[0].totalDocumentos) *
                              100
                            : 0
                        }
                      >
                        {progresos[0].totalDocumentos > 0
                          ? `${(
                              (progresos[0].procesados /
                                progresos[0].totalDocumentos) *
                              100
                            ).toFixed(2)}%`
                          : "0%"}
                      </Progress>
                      <p>
                        <strong>Tiempo estimado para finalizar:</strong>{" "}
                        {calcularTiempoEstimado(progresos[0], progresos[1])}
                      </p>
                      <p>
                        <strong>Próxima actualización en:</strong> {timeLeft}{" "}
                        segundos
                      </p>
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ConsultaEventosDian
