import React, { useEffect, useState, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import { utils, writeFile } from "xlsx"
import { useNavigate } from "react-router-dom"

// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"

const ConsultarEmpresas = props => {
  document.title = "Consultar Empresas | LS-DA - Softlogy"

  const [empresas, setEmpresas] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchEmpresas = useCallback(async currentUser => {
    if (!currentUser || !currentUser.IdEmpresa || !currentUser.access) {
      //console.log("User data not fully available yet, skipping fetch.")
      return
    }

    setLoading(true)
    //console.log("Fetching empresas data...")
    try {
      const response = await axios.get(
        `http://localhost:4000/hub-latam/rc1/empresas/obtener?id=${currentUser.IdEmpresa._id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        },
      )
      //console.log("Empresas data fetched:", response.data)
      setEmpresas(response.data)
    } catch (error) {
      console.error("Error fetching empresas data:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${currentUser ? `${currentUser.nombre} ${currentUser.apellido}` : "Usuario"}`

      fetchEmpresas(currentUser)
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Empresas", link: "#" },
        { title: "Consultar Empresas", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [fetchEmpresas, props])

  const handleExportToExcel = () => {
    const worksheet = utils.json_to_sheet(empresas)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "Empresas")
    writeFile(workbook, "Empresas.xlsx")
  }

  const handleModificar = id => {
    navigate(`/portal/empresa/${id}`)
  }

  const handleSucursales = id => {
    navigate(`/portal/sucursales/${id}`)
  }

  const handleResoluciones = (nit, id) => {
    //console.log("NIT ENVIADO: ", nit, "ID ENVIADO: ", id)
    navigate(`/portal/resoluciones/${nit}/${id}`)
  }

  const data = {
    columns: [
      {
        label: "Nombre",
        field: "nombre",
        sort: "asc",
        width: 150,
      },
      {
        label: "Identificación",
        field: "identificacion",
        sort: "asc",
        width: 270,
      },
      {
        label: "Tipo",
        field: "tipo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nombre Alianza",
        field: "nombreAlianza",
        sort: "asc",
        width: 100,
      },
      {
        label: "Acciones",
        field: "acciones",
        sort: "asc",
        width: 150,
      },
    ],
    rows: empresas.map(empresa => ({
      nombre: empresa.nombre,
      identificacion: empresa.identificacion,
      tipo: empresa.tipo,
      nombreAlianza: empresa.nombreAlianza || "N/A",
      acciones: (
        <>
          <button
            className="btn btn-warning me-2"
            onClick={() => handleModificar(empresa.identificacion)}
          >
            Modificar
          </button>
          <button
            className="btn btn-primary me-2"
            onClick={() => handleSucursales(empresa._id)}
          >
            Sucursales
          </button>
          <button
            className="btn btn-info"
            onClick={() =>
              handleResoluciones(empresa.identificacion, empresa._id)
            }
          >
            Resoluciones
          </button>
        </>
      ),
    })),
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Consultar Empresas</CardTitle>
                <Button
                  style={{
                    backgroundColor: "#007ea7",
                    color: "white",
                    border: "1px solid rgb(46, 58, 73)",
                  }}
                  variant="success"
                  onClick={handleExportToExcel}
                >
                  Exportar a Excel
                </Button>
                <Button
                  color="danger"
                  className="ms-2"
                  onClick={() => navigate("/portal/empresa/crear")}
                >
                  Crear Empresa
                </Button>
              </div>
              <p className="card-title-desc">
                Visualice y gestione la información de las empresas disponibles
                en el sistema.
              </p>

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  entriesLabel="Mostrar  registros"
                  searchLabel="Buscar:"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={[
                    "Mostrando registros del",
                    "al",
                    "de un total de",
                    "registros",
                  ]}
                  language={dataTableOptions}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(
  ConsultarEmpresas,
)
